$(function() {
  var myCarousel = document.querySelector(".carousel");

  var carousel = new bootstrap.Carousel(myCarousel, {
    interval: 7000,
    keyboard: false,
    pause: false,
    ride: "carousel",
    wrap: true
  });

  console.log("Site loaded ok");
});
